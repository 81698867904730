
export function avatarColor(name) {
    var h = 0;
    for (var i = 0; i < name.length; i++) {
      h = name.charCodeAt(i) + ((h << 5) - h);
    }
    
    h = h%360;
    return "hsl("+h+", 75%, 50%)";
}

export function makeTime(d) {
    return (new Date(d)).toLocaleString('en-US',{hour12:false}).replace(","," ");
}

export default { avatarColor, makeTime }
