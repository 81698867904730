import React, { Fragment, Component } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core'
import HomeAutomationTile from './HomeAutomationTile'

class HomeSection extends Component {
    tileStyle = {
        width: "150px",
        height: "150px",
        margin: "auto",
        marginBottom: "20px",
        textAlign: "center"
    }

    constructor(props) {
        super(props);
        this.server = props.server;

        this.state = {
            modules: {}
        };

        this.updateSwitches = this.updateSwitches.bind(this);

        this.server.getSwitches().then(this.updateSwitches);
    }

    updateSwitches(modules) {
        this.setState({ modules: modules || {} });
    }

    showModules() {
        console.log(this.state.modules);

        return Object.keys(this.state.modules).map(key => {
            console.log(key);
            console.log(this.state.modules[key]);
            return this.state.modules[key].modules.map(m => {
                if ("error" in m.status) {
                    return <Grid item xs={6} md={3} justify="center" alignItems="center">
                        <HomeAutomationTile name={m.name} type="missing" active={false} onChange={(val) => {}} /></Grid>
                }
                else if (key == "kasa") {
                        return <Grid item xs={6} md={3} justify="center" alignItems="center">
                            <HomeAutomationTile name={m.status.alias} type="light" active={m.status.state} onChange={(val) => {
                                this.server.setSwitch(m.id, val).then(this.updateSwitches);
                            }} /></Grid>
                }
                else if (key == "dwn") {
                    return <Grid item xs={6} md={3} justify="center" alignItems="center">
                        <HomeAutomationTile name={m.name} type="light" active={m.status.relays[0]} onChange={(val) => {
                            this.server.setSwitch(m.id, val).then(this.updateSwitches);
                        }} /></Grid>
                }
                return null;
            });

        });
    }

    render() {
        return <Fragment>
            <Typography variant="title">Home devices</Typography><br />
            <Grid container>
                {this.showModules()}
            </Grid>
        </Fragment>;
    }
}

export default HomeSection;
