import React, { Fragment, Component } from 'react';

class ThermostatControl extends Component {
    mainStyle = {
        padding:"10px",
        textAlign:"center"    
    }

    constructor(props) {
        super(props);
        this.server = props.server;
        this.state = {
            size: 200,
            stroke: 5,
            mode: props.mode||"off",
            fan: props.fan,
            active: props.active,
            temperature: Number.parseFloat(props.temperature)||0
        }

        this.onUp = this.onUp.bind(this);
        this.onDown = this.onDown.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            mode: props.mode||"off",
            fan: props.fan,
            active: props.active,
            temperature: Number.parseFloat(props.temperature)||0
        });
    }

    onUp() {
        this.props.onChange(this.state.temperature+0.5);
    }

    onDown() {
        this.props.onChange(this.state.temperature-0.5);
    }

    circle() {
        let r = this.state.size/2;
        let l = this.state.size;
        let s = this.state.stroke;
        let d1 = "M"+(l+s)+","+(r+s)+"a"+r+","+r+" 0 1,0 -"+l+",0";
        let d2 = "M"+s+","+(r+s)+"a"+r+","+r+" 0 1,0 "+l+",0";
        return[
            <path key="up" d={d1} class="up" stroke="url(#linear)" onClick={this.onUp}/>,
            <path key="down" d={d2} class="down" stroke="url(#linear)" onClick={this.onDown}/>
        ];
    }

    text() {
        let r = this.state.size/2;
        let l = this.state.size;
        let s = this.state.stroke;
        var low = ""; 
        var temp =  "--";
        var hi = "";
        if (this.state.mode != "off"){
            low = Number(this.state.temperature-0.5).toFixed(1);
            temp = Number(this.state.temperature).toFixed(1);
            hi = Number(this.state.temperature+0.5).toFixed(1);
        }
        return [
            <text key="hi" class="text" textAnchor="middle" dominantBaseline="hanging" x={r} y={s+5}>{hi}</text>,
            <text key="temp" class="text bigtext" textAnchor="middle" dominantBaseline="middle" x={r} y={s+r}>{temp}</text>,
            <text key="low" class="text" textAnchor="middle" dominantBaseline="baseline" x={r} y={l-5}>{low}</text>
        ];
    }

    icons() {
        let x = (this.state.size/2)-26;
        let y = (this.state.size/2)+32;
        let fanColour = this.state.fan?"#000000":"#D7D7D7";
        let statusColour = this.state.active?(this.state.mode=="cool"?"#5050FF":"#e24949"):"#D7D7D7";

        let fan = <svg x={x} y={y}><path fill={fanColour} d="M12,11A1,1 0 0,0 11,12A1,1 0 0,0 12,13A1,1 0 0,0 13,12A1,1 0 0,0 12,11M12.5,2C17,2 17.11,5.57 14.75,6.75C13.76,7.24 13.32,8.29 13.13,9.22C13.61,9.42 14.03,9.73 14.35,10.13C18.05,8.13 22.03,8.92 22.03,12.5C22.03,17 18.46,17.1 17.28,14.73C16.78,13.74 15.72,13.3 14.79,13.11C14.59,13.59 14.28,14 13.88,14.34C15.87,18.03 15.08,22 11.5,22C7,22 6.91,18.42 9.27,17.24C10.25,16.75 10.69,15.71 10.89,14.79C10.4,14.59 9.97,14.27 9.65,13.87C5.96,15.85 2,15.07 2,11.5C2,7 5.56,6.89 6.74,9.26C7.24,10.25 8.29,10.68 9.22,10.87C9.41,10.39 9.73,9.97 10.14,9.65C8.15,5.96 8.94,2 12.5,2Z" /></svg>;

        x+=26;
        let heat = <svg x={x} y={y}><path fill={statusColour} d="M7.95,3L6.53,5.19L7.95,7.4H7.94L5.95,10.5L4.22,9.6L5.64,7.39L4.22,5.19L6.22,2.09L7.95,3M13.95,2.89L12.53,5.1L13.95,7.3L13.94,7.31L11.95,10.4L10.22,9.5L11.64,7.3L10.22,5.1L12.22,2L13.95,2.89M20,2.89L18.56,5.1L20,7.3V7.31L18,10.4L16.25,9.5L17.67,7.3L16.25,5.1L18.25,2L20,2.89M2,22V14A2,2 0 0,1 4,12H20A2,2 0 0,1 22,14V22H20V20H4V22H2M6,14A1,1 0 0,0 5,15V17A1,1 0 0,0 6,18A1,1 0 0,0 7,17V15A1,1 0 0,0 6,14M10,14A1,1 0 0,0 9,15V17A1,1 0 0,0 10,18A1,1 0 0,0 11,17V15A1,1 0 0,0 10,14M14,14A1,1 0 0,0 13,15V17A1,1 0 0,0 14,18A1,1 0 0,0 15,17V15A1,1 0 0,0 14,14M18,14A1,1 0 0,0 17,15V17A1,1 0 0,0 18,18A1,1 0 0,0 19,17V15A1,1 0 0,0 18,14Z" /></svg>;

        let cool = <svg x={x} y={y}><path fill={statusColour} d="M20.79,13.95L18.46,14.57L16.46,13.44V10.56L18.46,9.43L20.79,10.05L21.31,8.12L19.54,7.65L20,5.88L18.07,5.36L17.45,7.69L15.45,8.82L13,7.38V5.12L14.71,3.41L13.29,2L12,3.29L10.71,2L9.29,3.41L11,5.12V7.38L8.5,8.82L6.5,7.69L5.92,5.36L4,5.88L4.47,7.65L2.7,8.12L3.22,10.05L5.55,9.43L7.55,10.56V13.45L5.55,14.58L3.22,13.96L2.7,15.89L4.47,16.36L4,18.12L5.93,18.64L6.55,16.31L8.55,15.18L11,16.62V18.88L9.29,20.59L10.71,22L12,20.71L13.29,22L14.7,20.59L13,18.88V16.62L15.5,15.17L17.5,16.3L18.12,18.63L20,18.12L19.53,16.35L21.3,15.88L20.79,13.95M9.5,10.56L12,9.11L14.5,10.56V13.44L12,14.89L9.5,13.44V10.56Z" /></svg>

        let icons = [fan];
        if (this.state.mode=="heat") icons.push(heat);
        if (this.state.mode=="cool") icons.push(cool);
        return icons;
    }

    css() {
        let upColour ="#F5F5F5";
        let downColour ="#F0F0F0";
        let strokeColour ="#D7D7D7";
        let pressColour = "#E8E8E8";
        let pressStrokeColour = "#D0D0D0";
        let tempColour = this.state.mode=="cool"?"#5050FF":"#e24949";

        let css = ".up         { fill:"+upColour+"; }"; 
        css +=    ".down       { fill:"+downColour+"; }";
        css +=    ".up,.down   { cursor: pointer; stroke-width: "+this.state.stroke+" } ";
        css +=    ".up:active,.down:active   { fill:"+pressColour+"; stroke:"+pressStrokeColour+";} ";
        css +=    ".text       { font-size: 24px; pointer-events: none; fill: "+strokeColour+"}";
        css +=    ".bigtext    { font-size: 64px; fill:"+tempColour+" }";

        return css;
    }

    render() {
        return <div style={this.mainStyle}>
            <svg width={this.state.size+(this.state.stroke*2)} height={this.state.size+(this.state.stroke*2)}>
                <style dangerouslySetInnerHTML={{__html: this.css()}} />
                <defs>
                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%"   stop-color="#EEEEEE"/>
                        <stop offset="25%"  stop-color="#CCCCCC"/>
                        <stop offset="50%"  stop-color="#EEEEEE"/>
                        <stop offset="75%"  stop-color="#CCCCCC"/>
                        <stop offset="100%" stop-color="#EEEEEE"/>
                    </linearGradient>
                </defs>
                {this.circle()}
                {this.text()}
                {this.icons()}
            </svg>
            </div>;
    }
}

export default ThermostatControl;
