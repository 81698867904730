import React, { Component } from 'react';
import Wait from './Wait';
import { Delete, ExpandMore } from '@material-ui/icons';
import { Schedule} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import {Avatar, Card, CardContent, CardHeader, CardMedia, CardActions} from '@material-ui/core'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Grid from '@material-ui/core/Grid';
import Theme from "./Theme";
import { makeTime, avatarColor } from './Utils';
class VoicemailPage extends Component {
    cardStyle = {
        //minWidth:"300px",
        //width:"",
        marginBottom: "10px",
        marginLeft: "10px"
    }
    constructor(props) {
        super(props);
        this.server = props.server;
        this.state = {
            voicemails: null
        };

        this.getVoicemails();
    }

    getVoicemails() {
        this.server.getVoicemails().then(v=>{
            this.setState({voicemails:v});
        });
    }

    deleteVoicemail(box,file) {
        this.server.deleteVoicemail(box,file).then(()=>this.getVoicemails());
    }

    showVoicemails() {
        return this.state.voicemails.map(v=>{
                let voicemails = v.msg.map(msg=>{
                            console.log(msg.file + "  "+ v.box);
                            let num = msg.from.match(/"(.*)"[^0-9]*([0-9]*)/);
                            let clid = num[2];
                            let name = num[1];
                            let col = avatarColor(name);
                            let audio = <audio id={msg.file+v.box} style={{width:"100%"}} controls><source src={"/api/voicemails/"+v.box+"/INBOX/"+msg.file}/></audio>;
                            let iconStyle = {verticalAlign:"middle",height:"16px"};
                            let time = <Typography style={{marginBottom:"10px"}} variant="title" ><Schedule style={iconStyle} />{makeTime(Number.parseInt(msg.time)*1000)}</Typography>;
                            return <Grid item xs={12} md={6}>
                                <Card style={this.cardStyle}>       
                                <CardHeader 
                                    avatar={<Avatar style={{backgroundColor:col}}>{name.substring(0,1).toUpperCase()}</Avatar>}
                                    action={<IconButton onClick={()=>{this.deleteVoicemail(v.box,msg.file)}}>
                                    <Delete/></IconButton>}
                                    title={name} 
                                    subheader={clid}/>
                                <CardContent style={{paddingTop:"30px"}}>
                                    {time}
                                    {audio}
                                </CardContent>
                            </Card></Grid>;
                });
                if (!voicemails.length) voicemails = <Typography variant="subheading">No voicemail</Typography>;
                return <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Typography variant="h6">INBOX {v.user||v.box}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                        <Grid style={{flexGrow:1}}><Grid container spacing={16}>
                            {voicemails}
                        </Grid></Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>;
            });
    }

    render() {
        let content = this.state.voicemails?this.showVoicemails():<Wait/>;
        return <div>{content}</div>;
    }
}

export default VoicemailPage;
