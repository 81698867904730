import React, { Fragment, Component } from 'react';
import { Switch, Grid, Paper, Typography } from '@material-ui/core'
import HomeAutomationTile from './HomeAutomationTile'

class IrrigationSection extends Component {
    tileStyle = {
        width: "150px",
        height: "150px",
        margin: "auto",
        marginBottom: "20px",
        textAlign: "center"
    }

    constructor(props) {
        super(props);
        this.server = props.server;

        this.state = {
            id: null,
            valves: [],
            sequences: [],
            schedulegarden: null,
            schedulelawn: null,
        };

        this.updateIrrigation = this.updateIrrigation.bind(this);

        this.server.getIrrigation().then(this.updateIrrigation);
    }

    updateIrrigation(modules) {
        this.setState({ id: modules.id, valves: modules.valves, sequences: modules.sequences, schedulegarden: modules.schedulegarden, schedulelawn: modules.schedulelawn });
    }

    showModules() {
        console.log(this.state);
        let valves = this.state.valves.map((m, index) => <Grid item xs={6} md={3} justify="center" alignItems="center">
            <HomeAutomationTile name={m.name} type="sprinkler" active={m.active} onChange={(val) => {
                this.server.setIrrigationValve(this.state.id, val ? (index + 1) : 0).then(this.updateIrrigation);
            }} />
        </Grid>);
        let sequences = this.state.sequences.map((m, index) => <Grid item xs={6} md={3} justify="center" alignItems="center">
            <HomeAutomationTile name={m.name} type="sequence" active={m.status} onChange={(val) => {
                this.server.setIrrigationSequence(this.state.id, m.name).then(this.updateIrrigation);
            }} />
        </Grid>);
        return valves.concat(sequences);

    }

    /*
    showSchedule() {
        return <Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle">Schedule</Typography><br/>
            </Grid>
            <Grid item xs={8} md={6}><div style={{marginTop:"13px"}}>
                <Typography variant="body1">{"Garden Schedule will activate sequence #0 at 04:00"}</Typography>
            </div></Grid>
            <Grid item xs={4} md={6}>
                <Switch style={{margin:"0px"}} checked={!!this.state.schedulegarden} color="primary" onChange={(ev,val)=>{
                    if (val) {
                        this.server.setGardenIrrigationSchedule().then(this.updateIrrigation); 
                    } else {
                        this.server.clearIrrigationSchedule(this.state.schedulegarden).then(this.updateIrrigation);
                    }
                }}/>
            </Grid>
            <Grid item xs={8} md={6}><div style={{marginTop:"13px"}}>
                <Typography variant="body1">{"Lawn Schedule will activate sequence #1 at 05:00"}</Typography>
            </div></Grid>
            <Grid item xs={4} md={6}>
                <Switch style={{margin:"0px"}} checked={!!this.state.schedulelawn} color="primary" onChange={(ev,val)=>{
                    if (val) {
                        this.server.setLawnIrrigationSchedule().then(this.updateIrrigation); 
                    } else {
                        this.server.clearIrrigationSchedule(this.state.schedulelawn).then(this.updateIrrigation);
                    }
                }}/>
            </Grid>
        </Fragment>
    }
    */
    render() {
        return <Fragment>
            <Typography variant="title">Irrigation</Typography><br />
            <Grid container>
                {this.showModules()}
            </Grid>
        </Fragment>
    }
}

export default IrrigationSection;
