import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class Wait extends Component {

    render() {
        return <CircularProgress />;
    }
}

export default Wait;
