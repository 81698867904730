import React, { Component } from 'react';
import {Grid, Paper, IconButton, Fab} from '@material-ui/core'
import { PowerSettingsNew, Warning } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

class HomeAutomationTile extends Component {
    tileStyle = {
        width: "150px",
        height: "150px",
        margin:"auto",
        padding: "10px",
        marginBottom: "20px",
        textAlign: "center"
    }

    iconStyle = {
        width: "72px",
        height: "72px"
    }

    constructor(props) {
        super(props);
        this.server = props.server;

        this.onClick = this.onClick.bind(this);
        this.state = {
            waiting: false,
            active: props.active,
            name: props.name
        };

    }

    componentWillReceiveProps(props) {
        this.setState({
            waiting: false, 
            active: props.active,
            name: props.name
        });
    }

    showIcon() {
        if (this.props.type == "light") {
            let s = {...this.iconStyle, color:this.state.active?"yellow":"white"};
            return <PowerSettingsNew style={s}/>;
        } else if (this.props.type == "missing") {
            let s = {...this.iconStyle, color:"white"};
            return <Warning style={s}/>;
        } else if (this.props.type == "sprinkler") {
            return <svg style={{width:"64px", height:"64px"}} viewBox="0 0 225 225">
                <path style={{fill:this.state.active?"yellow":"white"}} d="M205.153,127.687v12.744c0,4.143-3.357,7.5-7.5,7.5h-37.029c-4.143,0-7.5-3.357-7.5-7.5v-12.744c0-3.633,2.582-6.66,6.011-7.351v-14.346c0-2.56-2.083-4.643-4.643-4.643h-1.524c-6.898,12.417-19.909,20.193-34.423,20.193s-27.524-7.776-34.423-20.193H27.174c-4.143,0-7.5-3.357-7.5-7.5V69.081c0-4.143,3.357-7.5,7.5-7.5h57.719c4.748-7.771,12.021-13.585,20.452-16.592v-9.314c0-0.514,0.052-1.015,0.151-1.5H85.363c-4.143,0-7.5-3.357-7.5-7.5v-10.74c0-4.143,3.357-7.5,7.5-7.5h19.981V7.5c0-4.143,3.357-7.5,7.5-7.5h11.4c4.143,0,7.5,3.357,7.5,7.5v0.935h19.982c4.143,0,7.5,3.357,7.5,7.5v10.74c0,4.143-3.357,7.5-7.5,7.5h-20.133c0.098,0.485,0.151,0.986,0.151,1.5v9.314c8.431,3.007,15.704,8.82,20.452,16.592h14.438c17.659,0,32.026,14.366,32.026,32.025v26.656C202.326,120.755,205.153,123.887,205.153,127.687zM197.35,199.705c-0.09-0.451-0.22-0.887-0.387-1.305l-11.15-35.521c-0.98-3.127-3.879-5.254-7.155-5.254s-6.175,2.127-7.155,5.254l-11.316,36.051c-0.057,0.18-0.106,0.361-0.148,0.543c-0.6,1.902-0.902,3.859-0.902,5.831c0,10.765,8.758,19.522,19.522,19.522s19.522-8.758,19.522-19.522C198.18,203.413,197.9,201.535,197.35,199.705z"/>
            </svg>;
        } else if (this.props.type == "sequence") {
            return <svg style={{width:"64px", height:"64px"}} viewBox="0 0 24 24">
                <path style={{fill:this.state.active?"yellow":"white"}} d="M4,2C2.89,2 2,2.89 2,4V14H4V4H14V2H4M8,6C6.89,6 6,6.89 6,8V18H8V8H18V6H8M12,10C10.89,10 10,10.89 10,12V20C10,21.11 10.89,22 12,22H20C21.11,22 22,21.11 22,20V12C22,10.8921.11,10 20,10H12Z" />
            </svg>;
        }
    }

    showButton() {
        let s = {width:"90px", height:"90px"};
        if (this.state.waiting) return <CircularProgress style={s} />;
        return <Fab color="primary" style={s} onClick={this.onClick}>{this.showIcon()}</Fab>
    }
    
    onClick() {
        this.props.onChange(!this.state.active);
        this.setState({waiting:true});
    }

    render() {
        return <Paper style={this.tileStyle}>
                {this.showButton()}
                <div style={{marginTop:"10px"}}>{this.state.name}</div>
            </Paper>;
    }
}

export default HomeAutomationTile;
