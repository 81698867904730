import React, { Component, Fragment } from 'react';
import BackendServer from './BackendServer';
import PageHost from './PageHost';
import Menu from './Menu';
import { Redirect, Route } from 'react-router'
import { HashRouter, Switch } from 'react-router-dom'
import CDRPage from "./CDRPage";
import VoicemailPage from "./VoicemailPage";
import FinancesPage from "./FinancesPage";
import HomeAutomationPage from "./HomeAutomationPage.js"
import { Voicemail, AttachMoney, ListAlt, Home } from '@material-ui/icons';
import Login from './Login';
import Banner from './Banner';
import {version} from '../package.json';

class App extends Component {
    mainStyle = {
        width: "100%",
        
    }

    contentStyle = {
        maxWidth: "800px",
        minWidth: "570px",
        margin: "auto"
        
    }

    constructor() {
        super();
        console.log(version);
        this.server = new BackendServer();

        // TODO: this is temporary

        this.pages = [
            {name:"CDR", page:<CDRPage server={this.server}/>, icon:ListAlt},
            {name:"Voicemail", page:<VoicemailPage server={this.server}/>, icon:Voicemail },
            {name:"Automation", page:<HomeAutomationPage server={this.server}/>, icon:Home },
            {name:"Finances",page:<FinancesPage server={this.server}/>, icon:AttachMoney }
        ];

        let menu = <Menu items={this.pages}/>;
        this.routes = this.pages.map(i=>{
            let key = i.name.replace(" ","").toLowerCase();
            return <Route key={key} path={"/"+key} render={props => <PageHost menu={menu} name={i.name} page={i.page} server={this.server}/>} />;
        });
        this.routes.push(<Route key="login" path="/login" render={props => <Login server={this.server}/>} />);
        this.routes.push(<Redirect from="/" to="CDR" />);
    }

    render() {
        return (
                <div style={this.contentStyle} >
                    <Banner />
                    <HashRouter><Fragment>
                        <Switch>{this.routes}</Switch>
                    </Fragment></HashRouter>
                </div>
        );
    }
}
export default App;
