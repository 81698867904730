import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {Grid} from '@material-ui/core'

class MenuItem extends Component {
    buttonStyle = {
        paddingRight: "20px",
        paddingBottom: "10px"
    };

    render() {
        let key = this.props.name.replace(" ","").toLowerCase();
        let Icon = this.props.icon;
        return <Grid item xs={6} md={3} style={this.buttonStyle}>
            <Button fullWidth variant="outlined" size="large" component={Link} to={"/"+key}>
                <Icon style={{marginRight:"10px" }}/>
                {this.props.name}
            </Button>
        </Grid>;
    }
}

export default MenuItem;
