import React, { Component } from 'react';
import {Radio, RadioGroup, InputAdornment, TextField, Popover, Paper, Typography} from '@material-ui/core';
import {Grid, Fab, Button, FormControl, FormControlLabel} from '@material-ui/core';
import { Add } from '@material-ui/icons';


class AddTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addMenu: null,
            txtAmount:"",
            txtDescription:"",
            rbType: "1"
        }

        this.showMenu = this.showMenu.bind(this);
        this.onAddTransaction = this.onAddTransaction.bind(this);
        this.onAddChange = this.onAddChange.bind(this);
        
    }

    componentWillReceiveProps(props) {
        this.setState({});
    }

    onAddChange(ev) {
        if (ev.target.name === "description") this.setState({txtDescription:ev.target.value});
        if (ev.target.name === "amount") {
            let val = ev.target.value;  
            this.setState({txtAmount:ev.target.value});
        }
        if (ev.target.name === "type") {
            let amount = this.state.txtAmount;
            if (ev.target.value==="3") amount = this.props.deposit;
            this.setState({rbType:ev.target.value, txtAmount: amount});
        }
    }

    onAddTransaction() {
        let amount = Number.parseFloat(this.state.txtAmount);
        let desc = this.state.txtDescription;
        let type = this.state.rbType;
        if (type === "3" || type === "4") amount=amount*-1;
        this.props.onAdd(type, desc, amount);
        this.showMenu(null);
    }

    showAddMenu() {
        if (!this.state.addMenu) return null;
        return <Popover
                open={true}
                anchorEl={this.state.addMenu}
                onClose={()=>this.setState({addMenu:null})}
                PaperProps={{style:{width:"400px", padding:"10px", elevation:10}}}
                anchorOrigin={{vertical:"top", horizontal:"left"}} >

                <Typography>Add Transaction</Typography><br/>
                <TextField fullWidth label="Description" value={this.state.txtDescription} name="description" onChange={this.onAddChange} /><br/>
                <TextField type="number" 
                        fullWidth 
                        label="Amount" 
                        value={this.state.txtAmount} 
                        disabled={this.state.rbType==="3"}
                        name="amount" onChange={this.onAddChange} 
                        InputProps={{endAdornment: <InputAdornment position="end">$</InputAdornment>}} /><br/>
                <FormControl>
                <RadioGroup value={this.state.rbType} name="type" onChange={this.onAddChange}>
                    <FormControlLabel value="1" control={<Radio />} label="Expense" />
                    <FormControlLabel value="2" control={<Radio />} label="Deposit" />
                    <FormControlLabel value="3" control={<Radio />} label={"Monthly Charge ("+this.props.deposit.toFixed(2)+"$)"} />
                    <FormControlLabel value="4" control={<Radio />} label="Other Charge" />
                </RadioGroup>
                </FormControl>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={this.onAddTransaction}>Add</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={()=>this.showMenu(null)}>Cancel</Button>
                    </Grid>
                </Grid>
            </Popover>;        
    }

    showMenu(ev) {
        if (ev == null) {
            this.setState({
                addMenu: null,
                txtAmount: "",
                txtDescription:"",
                rbType: "1"
            });
            return;
        }
        this.setState({addMenu:ev.currentTarget});
    }

    render() {
        return <div style={{textAlign:"right", bottom:"10px", top:"100px", position:"sticky"}}>
                    <Fab color="secondary" onClick={this.showMenu}><Add/></Fab>
                    {this.showAddMenu()}
                </div>;
    }
}

export default AddTransaction;
