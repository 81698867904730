import React, { Component } from 'react';
import {Typography} from '@material-ui/core'
import Wait from "./Wait";
import Logo from "./voip.ms.svg";
import { Done } from '@material-ui/icons';

class VoipMSStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            voipms: null
        };

        this.server = props.server;
        this.server.getBalances().then((data)=>{
            if (data) this.setState({voipms:Number.parseFloat(data.voipms).toPrecision(4)});
        });
    }

    render() {
        let balance = this.state.voipms;
        if (balance === null) balance=<Wait/>; else balance = balance+"$";
        return <div style={{display:"flex", width:"150px"}}>
                <img alt="meow" src={Logo} style={{width:"64px", height:"32px", marginRight:"5px", verticalAlign:"middle"}}/>
                <Typography variant="h6" style={{display:"table-cell", verticalAlign:"middle", height:"32px", marginTop: "3px"}}>{balance}</Typography>
            </div>;
    }
}

export default VoipMSStatus;
