import React, { Fragment, Component } from 'react';
import {IconButton, Table, TableRow, TableCell, TableBody, TableHead, TableFooter, Tab, Tabs, AppBar} from '@material-ui/core';
import {Paper, Typography, Avatar, Grid} from '@material-ui/core';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import AddTransaction from './AddTransaction';
import { makeTime } from './Utils';

class FinancesPage extends Component {
    constructor(props) {
        super(props);
        this.server = props.server;
        this.state = {
            deleteTransaction: null,
            currentUser:null,
            addMenu:null,
            txtAmount:0,
            txtDescription:"",
            accounts: {    
                users: []
            }
        }

        this.updateAccounts = this.updateAccounts.bind(this);
        this.server.getAccounts().then(this.updateAccounts);
        
    }

    updateAccounts(data) {
        let u = this.state.currentUser;
        if (data.users && data.users.length>0 && this.state.currentUser===null) u=0;
        this.setState({accounts:data, currentUser: u});
    }

    showUserLabel(u) {
        return <Grid container>
                <Grid item xs={3}>
                    <Avatar color="primary">{u.name.substring(0,1).toUpperCase()}</Avatar>
                </Grid>
                <Grid item style={{textAlign:"right"}}>
                    <Typography>{u.name}</Typography>
                    <Typography variant="subtitle2" color={(u.total>=0)?"primary":"secondary"}>{u.total.toFixed(2)}$</Typography>
                </Grid>
            </Grid>;
    }

    showTabs() {
        return <AppBar position="static" color="default">
                <Tabs value={this.state.currentUser} 
                        onChange={(ev,val)=>this.setState({currentUser:val})}
                        variant="fullWidth" >
                    {this.state.accounts.users.map(u=> <Tab label={this.showUserLabel(u)} />) }
                </Tabs>
            </AppBar>;
    }

    showTransactions() {
        if (this.state.currentUser===null) return null;
        let user = this.state.accounts.users[this.state.currentUser];
        let table =  <Table padding="dense">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {user.transactions.map(t=><TableRow>
                    <TableCell padding="checkbox"><IconButton onClick={()=>this.setState({deleteTransaction:t})}><Delete /></IconButton></TableCell>
                    <TableCell>{makeTime(t.ts)}</TableCell>
                    <TableCell>{t.description}</TableCell>
                    <TableCell numeric>{t.amount.toFixed(2)}$</TableCell>
                    <TableCell></TableCell>
                    </TableRow>)}
            </TableBody>
            <TableFooter>
            </TableFooter>
        </Table>
        return <div>
                {table}
                <AddTransaction deposit={user.deposit} onAdd={(type,desc,amount)=>{
                    console.log(desc);
                    this.server.addTransaction(user.id, type, amount, desc).then(this.updateAccounts);
                }} />
            </div>;

    }

    showDeleteConfirm() {
        if (!this.state.deleteTransaction) return null;
        return <Dialog
              open={this.state.deleteTransaction!==null}
              onClose={()=>this.setState({deleteTransaction:null})} >
          <DialogTitle>{"Delete transaction of amount "+this.state.deleteTransaction.amount+"?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
                {"Are you sure you want to delete the transaction '"+this.state.deleteTransaction.description+"' of "+this.state.deleteTransaction.amount+"$ ? "}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.setState({deleteTransaction:null})} color="primary">
              No
            </Button>
            <Button onClick={()=>{
                    this.server.deleteTransaction(this.state.deleteTransaction.id).then(this.updateAccounts);
                    this.setState({deleteTransaction:null});
                }} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
    }

    render() {
        return <div>
                {this.showTabs()}
                <br/>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h4">Transactions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                {this.showTransactions()}
                {this.showDeleteConfirm()}
            </div>;
    }
}

export default FinancesPage;
