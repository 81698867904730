import React, { Component } from 'react';
import {TextField, Button, Grid} from '@material-ui/core'

class Login extends Component {
    constructor(props) {
        super(props);
        this.server = props.server;
        this.state = {
            username:"",
            password:""
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.server = props.server;
    }

    onChange(ev) {
        if (ev.target.name === "username") this.setState({username:ev.target.value});
        if (ev.target.name === "password") this.setState({password:ev.target.value});
    }

    onSubmit() {
        this.server.login(this.state.username,this.state.password).then(()=>{
            window.location = "/#/cdr";
        });
    }

    render() {
        return <Grid container style={{marginTop:"100px"}} spacing={16}>
            <Grid item xs={4}/>
            <Grid item xs={4}>
                <TextField value={this.state.username} variant="outlined" fullWidth name="username" label="User Name" onChange={this.onChange}/>
            </Grid>
            <Grid item xs={4}/>

            <Grid item xs={4}/>
            <Grid item xs={4}>
                <TextField value={this.state.password} variant="outlined" fullWidth name="password" label="Password" onChange={this.onChange} type="password" />
            </Grid>
            <Grid item xs={4}/>

            <Grid item xs={4}/>
            <Grid item xs={4}>
                <Button variant="contained" color="primary" fullWidth onClick={this.onSubmit}>Login</Button>
            </Grid>
            <Grid item xs={4}/>
        </Grid>;
    }
}

export default Login;
