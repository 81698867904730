import React, { Component } from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import Arrow from '@material-ui/icons/ArrowRight';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            items: props.items
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            value: props.value,
            items: props.items
        });
    }

    render() {
        let arrow = <path fill="#D7D7D7" d="M17.63,5.84C17.27,5.33 16.67,5 16,5H5A2,2 0 0,0 3,7V17A2,2 0 0,0 5,19H16C16.67,19 17.27,18.66 17.63,18.15L22,12L17.63,5.84Z" />;
        return <div style={{width:"100%"}}>
            <Typography>{this.props.label}</Typography>
            <List component="nav" style={{marginLeft:"10px"}} >
            {this.state.items.map(i=>{
                return <ListItem key={i} dense button disableGuttersi onClick={(index)=>this.props.onChange(i)} style={{padding:"0px"}}>
                        <ListItemIcon><svg width="24px" height="24px">
                            <line x1="50%" y1="0" x2="50%" y2="100%" style={{stroke:"#D7D7D7", strokeWidth:"2px"}} />
                            {this.state.value===i?arrow:null}
                        </svg></ListItemIcon>
                        <ListItemText primary={i} inset={this.state.value!==i} style={{paddingLeft:"0px"}}/>
                    </ListItem>;
            })}
        </List></div>;
    }
}

export default Select;
