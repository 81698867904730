import React, { Component } from 'react';
import MenuItem from './MenuItem';
import {Grid} from '@material-ui/core'

class Menu extends Component {
    menuStyle = {
        marginTop:"20px"
    }
    render() {
        
        let items =  this.props.items.map(i=>{
            return <MenuItem name={i.name} icon={i.icon} />
        });

        return <Grid container style={this.menuStyle}>
            {items}
        </Grid>;
    }
}

export default Menu;
