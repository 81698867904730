import React, { Component } from 'react';
import ThermostatControl from './ThermostatControl'
import { IconButton, FormControl, Switch, FormControlLabel, InputLabel, MenuItem, Grid } from '@material-ui/core'
import Select from './Select'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Restore } from '@material-ui/icons';
//import Theme from './Theme.js';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

class ThermostatSection extends Component {
    separatorStyle = {
        borderLeft: "1px solid #D7D7D7",
        paddingLeft: "5px",
        paddingRight: "5px",
        marginBottom: "20px",
    };

    constructor(props) {
        super(props);
        this.server = props.server;
        this.onThermostatChange = this.onThermostatChange.bind(this);
        this.updateThermostat = this.updateThermostat.bind(this);
        this.state = {
            setpoint: 0,
            model: "",
            fan: false,
            active: false,
            schedule: false,
            temperatures: ["n/a", "n/a", "n/a", "n/a", "n/a", "n/a", "n/a", "n/a"],
            mode: "off",
            probes: [],
            commands: []
        }
        this.server.getThermostat(this.props.id).then(this.updateThermostat);
    }


    updateThermostat(result) {
        console.log(result);
        let data = result.status;
        this.setState({
            active: data.running,
            fan: data.fan,
            mode: data.mode,
            model: data.model,
            schedule: data.schedule,
            temperatures: data.temperatures,
            setpoint: data.setpoint,
            probes: data.probes,
            commands: result.commands,
        });
    }

    onThermostatChange(val) {
        this.server.setThermostatSetPoint(this.props.id, val).then(this.updateThermostat);
    }

    showModeSelect() {
        return <Select label="Mode"
            items={["off", "heat", "cool"]}
            value={this.state.mode}
            onChange={(val) => {
                this.server.setThermostatMode(this.props.id, val).then(this.updateThermostat);
            }} />;
    }


    showScheduleSelect() {
        return <Select label="Schedule"
            items={["off", "enabled"]}
            value={this.state.schedule ? "enabled" : "off"}
            onChange={(val) => {
                this.server.setThermostatSchedule(this.props.id, val).then(this.updateThermostat);
            }} />;
    }

    showFilter() {
        return <div>
            <IconButton><Restore /></IconButton>
        </div>;
    }

    render() {
        let tc = <ThermostatControl
            temperature={this.state.setpoint}
            mode={this.state.mode}
            fan={this.state.fan}
            active={this.state.active}
            onChange={this.onThermostatChange} />;
        let sep1 = { ...this.separatorStyle, border: "0px" };
        let sep2 = { ...this.separatorStyle };
        let sep3 = { ...this.separatorStyle };

        if (isWidthDown('sm', this.props.width)) sep2.border = "0px";

        let canSetSchedule = this.state.commands.find(x=>x.name=="schedule");
        let canSetMode = this.state.commands.find(x=>x.name=="mode");

        return < Grid container >
            <Grid item xs={12} md={4} style={sep1} alignContent="center">
                {tc}
            </Grid>
            <Grid item xs={4} md={3} container direction="column" style={sep2}>
                {canSetMode && this.showModeSelect()}
                {canSetSchedule && this.showScheduleSelect()}
            </Grid>
            <Grid item xs={8} md={4} container direction="column" style={sep3}>
                <Table><TableBody>
                    {this.state.probes.map((item, idx) =>
                        <TableRow><TableCell padding="dense">{item}</TableCell><TableCell>{this.state.temperatures[idx]}</TableCell></TableRow>
                    )}
                </TableBody></Table>
            </Grid>
        </Grid >;
    }
}

export default withWidth()(ThermostatSection);
