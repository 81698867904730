
class BackendServer {
    sendServerRequest(url, req) {
        if (!req) req = {};
        req.credentials = "same-origin"; // required otherwise it wont send session cookie

        let p = new Promise((resolve, reject) => {
            fetch(url, req).then(r => {
                if (r.status === 401) {
                    window.location = "/#/login";
                } else if (r.status === 403) {
                    window.location = "/#/login";
                }

                if (r.status < 400) {
                    return r.json();
                } else {
                    return null;
                }
            }).then(data => {
                resolve(data);
            }).catch((error) => {
                console.log(error);
            });
        });
        return p;
    }

    getServerData(url) {
        return this.sendServerRequest(url, null);
    }

    postServerData(url, body) {
        var req = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: body
        };
        return this.sendServerRequest(url, req);
    }

    putServerData(url, body) {
        var req = {
            method: "PUT",
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: body
        };
        return this.sendServerRequest(url, req);
    }

    deleteServerData(url) {
        var req = {
            method: "DELETE"
        };
        return this.sendServerRequest(url, req);
    }


    ///////////////////////////////////////////////////////////////////
    // Telephony
    ///////////////////////////////////////////////////////////////////
    getCDRs(from, count) {
        return this.getServerData("/api/cdr?num=" + count + "&from=" + from);
    }

    getBlacklist() {
        return this.getServerData("/api/blacklist");
    }

    addToBlackList(num) {
        return this.putServerData("/api/blacklist/" + num);
    }

    removeFromBlackList(num) {
        return this.deleteServerData("/api/blacklist/" + num);
    }

    getBalances() {
        return this.getServerData("/api/balances");
    }

    getVoicemails() {
        return this.getServerData("/api/voicemail");
    }

    deleteVoicemail(user, msg) {
        return this.deleteServerData("/api/voicemail/" + user + "/" + msg);
    }

    ///////////////////////////////////////////////////////////////////
    // HomeAutomation
    ///////////////////////////////////////////////////////////////////
    setThermostatSchedule(id) {
        return this.postServerData("/api/thermostat/" + id + "/schedule");
    }

    setThermostatMode(id, newmode) {
        return this.postServerData("/api/thermostat/" + id + "/mode", JSON.stringify({ mode: newmode }));
    }

    getThermostat(id) {
        return this.getServerData("/api/thermostat/" + id);
    }

    getThermostatList() {
        return this.getServerData("/api/thermostats");
    }

    getSwitches() {
        return this.getServerData("/api/switches");
    }

    setSwitch(id, val) {
        return this.postServerData("/api/switches/" + id, JSON.stringify({ state: val }));
    }

    getIrrigation() {
        return this.getServerData("/api/irrigation");
    }

    setAlarmState(armed) {
        if (armed) return this.postServerData("/api/alarmarm");
        else return this.postServerData("/api/alarmdisarm");
    }

    getAlarmState() {
        return this.getServerData("/api/alarmstate");
    }

    setIrrigationSequence(id, seqname) {
        return this.postServerData("/api/irrigation/sequence/" + id, JSON.stringify({ seqname: seqname }));
    }

    setIrrigationValve(id, valve) {
        return this.postServerData("/api/irrigation/valve/" + id, JSON.stringify({ valve: valve }));
    }

    setLawnIrrigationSchedule() {
        return this.putServerData("/api/irrigation/schedulelawn");
    }

    setGardenIrrigationSchedule() {
        return this.putServerData("/api/irrigation/schedulegarden");
    }


    clearIrrigationSchedule(id) {
        return this.deleteServerData("/api/irrigation/schedule/" + id);
    }

    setThermostatSetPoint(id, setpoint) {
        return this.postServerData("/api/thermostat/" + id + "/setpoint", JSON.stringify({ setpoint: setpoint }));
    }

    ///////////////////////////////////////////////////////////////////
    // Accounting
    ///////////////////////////////////////////////////////////////////
    getAccounts() {
        return this.getServerData("/api/accounting");
    }

    addTransaction(user, type, amount, description) {
        return this.postServerData("/api/transaction", JSON.stringify({ user: user, type: type, amount: amount, description: description }));
    }

    deleteTransaction(id) {
        return this.deleteServerData("/api/transaction/" + id);
    }

    ///////////////////////////////////////////////////////////////////
    // Login
    ///////////////////////////////////////////////////////////////////
    login(user, pass) {
        var data = JSON.stringify({
            username: user,
            password: pass
        });
        return this.postServerData("/login", data);
    }

    logout() {
        return this.deleteServerData("/api/login");
    }


}

export default BackendServer;
