import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper'
import Banner from './Banner';
import { Voicemail, AttachMoney, ListAlt, Home } from '@material-ui/icons';

class PageHost extends Component {
    containerStyle = {
        marginTop: "20px",
        padding:"5px",
        minHeight: "800px"
    }

    constructor(props) {
        super(props);
        this.state = {
            page: props.page,
            name: props.name,
            menu: props.menu
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            page: props.page,
            menu: props.menu,
            name: props.name
        });
    }

    render() {
        return <Fragment>
                    <div style={{padding:"5px"}}>
                        {this.state.menu}
                        <Paper style={this.containerStyle}>
                            {this.state.page}
                        </Paper>
                    </div>
                </Fragment>;
    }
}

export default PageHost;
