import React, { Component } from 'react';
import { HourglassEmpty, ArrowRightAlt, Schedule, Block, Done } from '@material-ui/icons';
import {Grid, Table, TableBody, TableCell, TableHead, TableFooter, TablePagination, TableRow} from '@material-ui/core';
import {Avatar, Typography} from '@material-ui/core';
import Theme from './Theme';
import IconButton from '@material-ui/core/IconButton';
import VoipMSStatus from './VoipMSStatus';
import Wait from './Wait';
import { avatarColor, makeTime } from './Utils';

class CDRPage extends Component {
    navButtonStyle = {
        display: "block",
        padding: "5px",
        width: "30px",
        height: "20px",
        cursor: "pointer"
        
    }

    blockedRowStyle = {
        backgroundColor: "#FFEEEE"
    };

    normalRowStyle = {
    };

    constructor(props) {
        super(props);
        this.server = props.server;
        this.state = {
            cdrs: null
        };

        this.rowsPerPage = 50;
        this.from = 0;
        this.getCDRs();
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    }
    
    getCDRs() {
        this.setState({wait:true});
        let p = [this.server.getCDRs(this.from,this.rowsPerPage), this.server.getBlacklist() ];
        Promise.all(p).then(data=>{
            this.updateBlackList(data[1]);
            this.setState({cdrs:data[0], wait:false});
        });
    }
    
    updateBlackList(bl) {
        this.setState({blacklist:bl});
    }

    addBlackList(num) {
        this.server.addToBlackList(num).then(()=>{
            this.server.getBlacklist().then((data)=>this.updateBlackList(data));
        });
        console.log(num);
    }

    removeBlackList(num) {
        this.server.removeFromBlackList(num).then(()=>{
            this.server.getBlacklist().then((data)=>this.updateBlackList(data));
        });
        console.log(num);
    }

    showCDRs() {
        if (this.state.wait) return <Wait/>;
        let header = <TableRow>
                <TableCell>From</TableCell>
        </TableRow>;

        let rows = (!this.state.cdrs)?null:this.state.cdrs.map(cdr=>{
            let clid = cdr.clid.replace(/[^0-9]*/g, '');
            let name = cdr.clid.match(/.*"(.*)"/)[1];
            var avatar = "?";
            if (!name) {
                name = "<UNKNOWN>";
            } else {
                avatar = name.substring(0,1).toUpperCase();
            }
            var col = avatarColor(name);
            let iconStyle = {verticalAlign:"middle",height:"16px"};
            let blocked = this.state.blacklist && this.state.blacklist.includes(clid);
            let blockButton = (!blocked)?<IconButton onClick={()=>this.addBlackList(clid)}><Block/></IconButton>:<IconButton onClick={()=>this.removeBlackList(clid)}><Done/></IconButton>;
            return <TableRow style={blocked?this.blockedRowStyle:this.normalRowStyle} >
                    <TableCell >
                        <Grid container>
                            <Grid item xs={1}>
                                <Avatar style={{backgroundColor:col}}>{avatar}</Avatar>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant="h6">{name}</Typography>
                                <Typography variant="subtitle1" color="textSecondary">{cdr.src}</Typography>
                            </Grid>
                            <Grid item xs={4} md={6}>
                                <Schedule style={iconStyle}/> {makeTime(cdr.calldate)}<br/>
                                <HourglassEmpty style={iconStyle}/>{cdr.duration+" s"}<br/>
                                <ArrowRightAlt style={iconStyle}/>{cdr.dst}
                            </Grid>
                            <Grid item xs={1}>
                                {blockButton}
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>;
        });
        return <Table padding="checkbox">
                <TableBody>{rows}</TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                        colSpan={6}
                        count={1000}
                        rowsPerPage={this.rowsPerPage}
                        page={this.from/this.rowsPerPage}
                        SelectProps={{
                            native: true,
                        }}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
            </Table>;
    }

    onChangeRowsPerPage(event) {
        this.rowsPerPage = event.target.value;
        this.getCDRs();
    }

    onChangePage(ev,page) {
        this.from = page*this.rowsPerPage;
        this.getCDRs();
    }

    render() {
        return <Grid container direction="row-reverse">
                <Grid item md={2} xs={3}><VoipMSStatus server={this.server} /></Grid>
                <Grid item xs={12}>{this.showCDRs()}</Grid>
            </Grid>;
    }
}

export default CDRPage;


