import React, { Fragment, Component } from 'react';
import {Grid, Button} from '@material-ui/core';
import {avatarColor, makeTime} from './Utils';
import { Lock, LockOpen } from '@material-ui/icons';
import {Typography} from '@material-ui/core';

class AlarmSection extends Component {
    constructor(props) {
        super(props);
        this.server = props.server;
        this.state = {
            logs: null
        };

        this.updateState = this.updateState.bind(this);
        this.server.getAlarmState().then(this.updateState);
    }

    updateState(data) {
        console.log(data);
        this.setState({alarm:data});
    }

    lockStatus = () => {
        if (!("alarm" in this.state)) return null;
        if (!this.state.alarm) return null;
        let s = this.state.alarm.state;
        return <Fragment>
                <Grid item xs={1}>
                    {["armed", "alarming", "grace"].includes(s) && <Lock /> || <LockOpen />}
                </Grid>
                <Grid item xs={3}><Typography variant="subtitle1">System {s}</Typography></Grid>
            </Fragment>;
    }

    showButton = () =>{
        if (!("alarm" in this.state)) return null;
        if (!this.state.alarm) return null;

        let s = this.state.alarm.state;
        return <Fragment>
                <Grid item xs={6}>
                    {s == "disarmed" && <Button variant="contained" onClick={()=>{this.server.setAlarmState(true).then(this.updateState)}}>Arm</Button>}
                    {s != "disarmed" && <Button variant="contained" onClick={()=>{this.server.setAlarmState(false).then(this.updateState)}}>Disarm</Button>}
                </Grid>
            </Fragment>;
    }

    showStatus = () =>{
        return <Grid container>
            {this.lockStatus()}            
            {this.showButton()}
            </Grid>
    }

    render() {
        return <Fragment>   
            <Typography variant="title">Garage Alarm System</Typography><br/>
            {this.showStatus()}
        </Fragment>;
    }
}

export default AlarmSection;
