import React, { Component, Fragment } from 'react';
import IrrigationSection from "./IrrigationSection"
import HomeSection from "./HomeSection"
import ThermostatSection from "./ThermostatSection"
import AlarmSection from "./AlarmSection"
import { Divider, Typography } from '@material-ui/core';

class HomeAutomationPage extends Component {
    constructor(props) {
        super(props);
        this.server = props.server;
        this.updateThermostatList = this.updateThermostatList.bind(this);
        this.state = {
            "thermostats": []
        }
    }

    updateThermostatList(thermostats) {
        this.setState({ "thermostats": thermostats })
    }

    componentDidMount() {
        this.server.getThermostatList().then(this.updateThermostatList);
    }

    render() {
        return <div style={{ padding: "10px" }}>
            {this.state.thermostats.map(item => <Fragment>
                <Typography variant="title">{item.name}</Typography ><br />
                <ThermostatSection server={this.server} id={item.id} />
                <Divider /><br />
            </Fragment>)}

            <HomeSection server={this.server} />
            <Divider /><br />

            <IrrigationSection server={this.server} />
        </div >;
    }
}

export default HomeAutomationPage;
