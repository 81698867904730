import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { Email, Lock, LockOpen, Voicemail } from '@material-ui/icons';

class Banner extends Component {
    bannerStyle = {
        colorDefault: "#2196F3"
    };

    render() {
        /*let badges = <Badge badgeContent={4} color="primary">
                    <Voicemail />
                </Badge>
                <Badge badgeContent={4} color="primary" invisible>
                    <Lock style={{marginLeft:"20px"}}/>
                </Badge>;*/

        return <AppBar position="static" style={this.bannerStyle} color="default">
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit">
                    Dumais Intranet
                </Typography>
                <div style={{flexGrow:1}}></div>
                
            </Toolbar>
        </AppBar>
    }
}

export default Banner;
